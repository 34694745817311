<template>
  <div class="animate-pulse flex flex-col justify-between">
    <div class="rounded-lg bg-gray-300 dark:bg-zinc-800 w-full h-full"></div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoader',
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
